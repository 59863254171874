import fullScreen from './modules/fullscreen';
import maintenance from './modules/maintenance';
import hoverVideos from './modules/hoverVideo';


document.addEventListener('DOMContentLoaded', function () {

    try {
        fullScreen();
    } catch (error) {}


    try {
        maintenance();
    } catch (error) {}

    try {
        setTimeout(() => {
            hoverVideos();
        }, 300)
    } catch (error) {}

    try {
        if (document.documentElement.clientWidth > 480) {
            $(".main-nav").ticker({
                item: 'a',
                speed: 200,
                pauseAt: '',
                direction: 'right',
                delay: 500,
                pauseOnHover: !0
            });
            $(".main-nav2").ticker({
                item: 'a',
                speed: 200,
                pauseAt: '',
                pauseOnHover: !0
            });
        }
    } catch (error) {}


    try {
        let langLink = document.querySelector('.lang__link');

        langLink.addEventListener('click', showLang)

        function showLang() {
            if (this.classList.contains('active')) {
                this.classList.remove('active');
                document.querySelector('.lang__body').classList.remove('fadeIn');
                document.querySelector('.lang__body').classList.add('fadeOut');
                langLink.removeEventListener('click', showLang);
                setTimeout(() => {
                    document.querySelector('.lang__body').style.display = "none";
                    langLink.addEventListener('click', showLang);
                }, 500);
            } else {
                this.classList.add('active');
                document.querySelector('.lang__body').style.display = "flex";
                document.querySelector('.lang__body').classList.add('fadeIn');
                document.querySelector('.lang__body').classList.remove('fadeOut');
            }
        }
    } catch (error) {}


    try {
        let burger = document.querySelector('.burger');
        let menu = document.querySelector('.menu');

        burger.addEventListener('click', showMenu);

        function showMenu(e) {
            e.preventDefault();
            if (!this.classList.contains('active')) {
                this.classList.add('active');
                menu.classList.add('active');
                menu.classList.remove('slideOut');
                burger.removeEventListener('click', showMenu);
                setTimeout(() => {
                    burger.addEventListener('click', showMenu);
                }, 1000);
            } else {
                this.classList.remove('active');
                menu.classList.add('slideOut');
                burger.removeEventListener('click', showMenu);
                setTimeout(() => {
                    menu.classList.remove('active');
                    burger.addEventListener('click', showMenu);
                }, 1000);
            }
        }

    } catch (error) {}

    try {
        let linkHov = document.querySelector('.link_body');
        linkHov.addEventListener('mouseover', () => {
            linkHov.closest('.about-us-s').classList.add('active-s');
        });
        linkHov.addEventListener('mouseleave', () => {
            linkHov.closest('.about-us-s').classList.remove('active-s');
        });
    } catch (e) {}


    try {
        $('#pagepiling').pagepiling({
            anchors: ['firstPage', 'secondPage', 'thirdPage', 'fourthPage', 'lastPage'],
            onLeave: function (index, nextIndex, direction) {
                //after leaving section 2
                if (index == 2 && direction == 'down') {
                    $.fn.pagepiling.setAllowScrolling(false);
                    setTimeout(() => {
                        $.fn.pagepiling.setAllowScrolling(true);
                    }, 500);
                }

            }
        });
    } catch (error) {}

    try {
        var swiper = new Swiper(".projects-slider", {
            slidesPerView: 3,
            centeredSlides: true,
            slidesPerView: "auto",
            // loop: true,
            // loopFillGroupWithBlank: true,
            scrollbar: {
                el: ".swiper-scrollbar",
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: ".swiper-pagination",
                type: "fraction",
            },
        });
    } catch (error) {}


    try {

        const swiper2 = new Swiper('.swiper-container2', {
            // Optional parameters
            direction: 'horizontal',
            loop: false,
            mousewheel: true,
            speed: 1000,
            navigation: {
                nextEl: '.scroll_link ',
            },
            breakpoints: {
                480: {
                    direction: "horizontal",
                },
                320: {
                    direction: "vertical",
                },
            }
        });

        swiper2.on('slideChange', function () {
            $.fn.pagepiling.setAllowScrolling(false);
        });
        swiper2.on('reachEnd', function () {
            setTimeout(() => {
                $.fn.pagepiling.setAllowScrolling(true);
            }, 700);
        });
        swiper2.on('reachBeginning', function () {
            setTimeout(() => {
                $.fn.pagepiling.setAllowScrolling(true);
            }, 700);
        });
    } catch (error) {}

    try {
        document.querySelector('.scroll-btn').addEventListener('click', () => {
            $.fn.pagepiling.moveSectionDown();
        });
    } catch (error) {}
    try {
        document.querySelector('.accept-btn').addEventListener('click', () => {
            document.querySelector('.cookies').classList.add('fadeOut');
            setTimeout(() => {
                document.querySelector('.cookies').style.display = "none";
            }, 500)
        })
    } catch (error) {}

});