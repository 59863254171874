const hoverVideos = () => {

    function addEventListenerOnMouseOver (links, video) {
        let mainNavLink = document.querySelectorAll(links),
        mainVideos = document.querySelector(video);

        mainNavLink.forEach(link => {
            link.addEventListener('mouseover', function() {
                let that = this;

                that.closest('.main-nav-list').classList.add('active');
                document.querySelector('.videos').style.display = 'block';
                mainVideos.classList.add('fadeIn', 'db');
                mainVideos.classList.remove('fadeOut');
                mainVideos.querySelector('video').play();
            }); 
        });
    }
    function addEventListenerOnMouseLeave (links, video) {
        let mainNavLink = document.querySelectorAll(links),
        mainVideos = document.querySelector(video);

        mainNavLink.forEach(link => {
            link.addEventListener('mouseleave', function() {
                let that = this;

                that.closest('.main-nav-list').classList.remove('active');
                document.querySelector('.videos').style.display = 'none';
                mainVideos.classList.remove('fadeIn', 'db');
                mainVideos.classList.add('fadeOut');
                setTimeout(() => {
                    mainVideos.classList.add('dn');
                }, 400)
                mainVideos.querySelector('video').pause();
            }); 
        });
    }

    document.querySelectorAll('.main-nav-list').forEach(item => {
        item.classList.remove('active');
    });

    addEventListenerOnMouseOver('.develop-video-link', '.develop-video');
    addEventListenerOnMouseLeave('.develop-video-link', '.develop-video');

    addEventListenerOnMouseOver('.design-video-link', '.design-video');
    addEventListenerOnMouseLeave('.design-video-link', '.design-video');

    addEventListenerOnMouseOver('.marketing-video-link', '.marketing-video');
    addEventListenerOnMouseLeave('.marketing-video-link', '.marketing-video');
    
    addEventListenerOnMouseOver('.maintenance-video-link', '.maintenance-video');
    addEventListenerOnMouseLeave('.maintenance-video-link', '.maintenance-video');

    addEventListenerOnMouseOver('.innovative-video-link', '.innovative-video');
    addEventListenerOnMouseLeave('.innovative-video-link', '.innovative-video');
}

export default hoverVideos;